import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import router from './router'

// import Swal from 'sweetalert2'
// app.config.globalProperties.$swal = Swal;

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
app.use(VueSweetalert2);

import 'popper.js'
import 'jquery'
import 'bootstrap/dist/css/bootstrap.css'

import VueFeather from 'vue-feather';
app.component(VueFeather.name, VueFeather);

app.use(router).mount('#app')
