<template>
  <div class="header text-center">
    <div class="pt-2 px-1 mb-1 my_border_bottom" style="height:40px;">
      <div class="float-left">
        <!-- backButton -->
        <div class="d-inline-block float-left" style="cursor: pointer;  margin-right:5px;"
          @click="listRedirect"
          @touch="listRedirect"
        >
          <vue-feather size="30" type="arrow-left" strokeWidth="2" stroke="#ba2b31" fill="none" class="float-left"></vue-feather>
          <div class="d-inline text-danger pt-2" style="font-size: 20px;">
              Назад
          </div>
        </div>
        <div class="d-inline-block ms-5">
          <vue-feather size="15" type="clock" strokeWidth="2" fill="none"></vue-feather>
          <div class="d-inline pt-2" style="font-size: 20px;">
              Ошибка 404
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class=""
  @click="listRedirect"
  @touch="listRedirect"
  >
    <img src="https://assets.codepen.io/5647096/backToTheHomepage.png" alt="" style="width:100%; max-width: 500px;">
    <img src="https://assets.codepen.io/5647096/Delorean.png" alt="" style="width:100%; max-width: 500px;">
    <button class="mt-2 btn btn-lg btn-secondary btn-block">Список тренировок</button>
  </div>


</template>

<script>
export default {
  name: 'ErrorPage404',
  data () {
     return {
    }
  },
  mounted () {
    console.log('mounted');
  },
  computed: {
  },
  watch: {

  },

  methods: {
    listRedirect () {
      this.$router.push({ path: '/'});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
