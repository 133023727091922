<template>
  <div>
    <h2>Schedule Editor</h2>

    <ul>
      <li v-for="day in schedule" :key="day.day">
        <h3>{{ day.name }}</h3>
        <ul>
          <li v-for="workout in day.workouts" :key="workout.time">
            <div>
              <label>Time:</label>
              <input v-model="workout.time" />
            </div>
            <div>
              <label>Name:</label>
              <input v-model="workout.name" />
            </div>
            <div>
              <label>Min People:</label>
              <input v-model="workout.minPeoples" type="number" />
            </div>
            <div>
              <label>Max Places:</label>
              <input v-model="workout.maxPlaces" type="number" />
            </div>
          </li>
        </ul>
        <button @click="addWorkout(day.day)">Add Workout</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schedule: [
        {
          name: "Понедельник",
          day: 1,
          workouts: [
            {
              time: "07:10",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
            {
              time: "11:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
            {
              time: "19:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
          ],
        },
        {
          name: "Вторник",
          day: 2,
          workouts: [
            {
              time: "19:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
          ],
        },
        {
          name: "Среда",
          day: 3,
          workouts: [
            {
              time: "07:10",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
            {
              time: "11:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
            {
              time: "19:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
          ],
        },
        {
          name: "Четверг",
          day: 4,
          workouts: [
            {
              time: "19:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
          ],
        },
        {
          name: "Пятница",
          day: 5,
          workouts: [
            {
              time: "07:10",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
            {
              time: "11:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
            {
              time: "19:00",
              name: "Сайкл PRO",
              minPeoples: 5,
              maxPlaces: 14,
            },
          ],
        },
        {
          name: "Суббота",
          day: 6,
          workouts: [],
        },
        {
          name: "Воскресение",
          day: 7,
          workouts: [],
        },
      ],
    };
  },
  methods: {
    addWorkout(day) {
      const newWorkout = {
        time: "00:00",
        name: "New Workout",
        minPeoples: 5,
        maxPlaces: 14,
      };
      const dayIndex = this.schedule.findIndex((d) => d.day === day);
      if (dayIndex !== -1) {
        this.schedule[dayIndex].workouts.push(newWorkout);
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
