<template >
  <div v-if="!isRefreshing" style="min-height: 100vh">
    <div class="header" style="height: 45px">
      <!-- backButton -->
      <div
        class="d-inline-block text-danger cursor-pointer-wrapper"
        style="margin-right: 10px"
        @click="listRedirect"
        @touch="listRedirect"
      >
        <vue-feather
          size="28"
          type="arrow-left"
          strokeWidth="2"
          stroke="#ba2b31"
          fill="none"
          class="align-middle"
        ></vue-feather>
        <span class="align-middle">Назад</span>
      </div>

      <!-- componenetInfo -->
      <div class="d-inline-block mt-3">
        <vue-feather
          size="15"
          type="settings"
          strokeWidth="2"
          stroke="white"
          fill="none"
        ></vue-feather>
        <div class="d-inline ms-1">ПОЛЬЗОВАТЕЛИ</div>
      </div>
      
    </div>

    <div>
      <div class="mt-3 d-block pl-1 pr-2" style="height: 60px">
        <vue-feather
          class="mx-2"
          size="32"
          type="filter"
          strokeWidth="2"
          stroke="white"
          fill="none"
          style="float: left; margin-top: 5px"
        ></vue-feather>
        <input
          ref="filterField"
          v-on:keyup="filterKeyUpHandler($event)"
          placeholder="Введите имя или номер..."
          style="float: left; font-size: 25px; width: calc(100% - 127px)"
        />

        <div
          class="d-inline-block"
          style="float: right"
          @click="clearFilterField()"
          @touch="clearFilterField()"
        >
          <div
            class="btn btn-secondary btn-block"
            style="height: 44px; float: right; font-size: 20px"
          >
            <vue-feather
              class="mx-1"
              size="30"
              type="x"
              strokeWidth="2"
              stroke="white"
              fill="none"
              style="float: left"
            ></vue-feather>
          </div>
        </div>
      </div>

      <div v-for="(user, userIndex) in filteredUsers" :key="userIndex">

          <div
            v-if="user.isDeleted == 0"
            class="w-100 mt-3"
            style="min-width: 200px"
          >

          <table class="table table-dark table-borderless">
            <thead>
              <tr>
                <th class="d-inline-block" style="font-size: 20px; ">
                  <div style="font-size: 10px">
                    ФИО клиента:
                  </div>
                  <input style="height: 40px;"
                    v-model="filteredUsers[userIndex].name"
                  />
                </th>
                <th>
                  <div style="font-size:10px">роль:</div>
                  <select
                    v-model="filteredUsers[userIndex].role"
                    class=""
                    name=""
                  >
                    <option>admin</option>
                    <option>raider</option>
                    <option>user</option>
                  </select>
                </th>
                <th style="font-size: 12px">
                  <div>
                    {{ user.phoneId }} <br> {{  user.birthDay }}
                  </div>
                </th>
                <th style="font-size: 12px">
                  <div>
                    <div style="font-size: 12px">
                      tg chat id:
                    </div>
                    {{  user.chatId }}
                  </div>
                </th>
              </tr>
            </thead>
            <!-- <thead style="border-top: 1px solid rgb(56, 56, 56)">
              <tr>
                <th scope="col">Тренировка</th>
                <th scope="col">Остаток</th>
                <th scope="col" colspan="2">Управление остатком</th>
              </tr>
            </thead> -->
            <tbody style="border-top: 1px solid rgb(56, 56, 56)">

              <tr style="font-size: 12px">
                <td colspan="4">Управление остатками:</td>
              </tr>
              <tr v-for="service in servicesMap" :key="service">
                <th style="font-size:20px;">{{ service.name }}</th>
                <td style="font-size:25px;">
                  {{ filteredUsers[userIndex][service.count] }} 
                  <div v-if="filteredUsers[userIndex][service.plus]" class="d-inline-block" style="color:red;">
                    +{{ filteredUsers[userIndex][service.plus] }}
                  </div>
                </td>
                <td style="min-width: 167px;">
                  <button class="btn btn-sm btn-success btn-block m-1" style="width: 42px;" @click="filteredUsers[userIndex][service.plus] = 0"> 0</button>
                  <button class="btn btn-sm btn-success btn-block m-1" style="width: 42px;" @click="filteredUsers[userIndex][service.plus] += 1"> +1</button>
                  <button class="btn btn-sm btn-success btn-block m-1" style="width: 42px;" @click="filteredUsers[userIndex][service.plus] += 10"> +10</button>
                </td>
                <td>
                  <button class="btn btn-sm btn-outline-success btn-block m-1" @click="saveCopy(userIndex)">                   
                    <vue-feather size="16" type="save" strokeWidth="2" stroke="white" fill="none" style="margin: 0px" ></vue-feather>
                    Изменить
                  </button>
                </td>
              </tr>
            </tbody>
          </table>



            <div class="">
              <div
                class="d-inline-block ps-1"
                style="padding-right: 10px"
              >

                
                <div
                  class="d-inline-block"
                  style="width: 43px; padding-right: 3px"
                >
                <div
                  class="float-start btn btn-outline-info"
                  style="padding: 0px"
                  @click="sendInvite(userIndex)"
                  @touch="sendInvite(userIndex)"
                >
                  <vue-feather
                    size="24"
                    type="send"
                    strokeWidth="2"
                    stroke="white"
                    fill="none"
                    style="margin-top: 10px"
                  ></vue-feather>
                  <div
                    class="d-block float-start text-center ps-2"
                    style="font-size: 10px; margin-top: -5px"
                  >
                    send
                  </div>
                  <div
                    class="d-block float-start text-center ps-2"
                    style="font-size: 10px; margin-top: -5px"
                  >
                    msg
                  </div>
                </div>
              </div>


              <div
                class="d-inline-block"
                style="width: 43px; padding-right: 3px"
              >
                <div
                  class="float-start btn btn-outline-success"
                  style="padding: 0px"
                  @click="saveCopy(userIndex)"
                  @touch="saveCopy(userIndex)"
                >
                  <vue-feather
                    size="24"
                    type="save"
                    strokeWidth="2"
                    stroke="white"
                    fill="none"
                    style="margin-top: 10px"
                  ></vue-feather>
                  <div
                    class="d-block float-start text-center ps-2"
                    style="font-size: 10px; margin-top: -5px"
                  >
                    save
                  </div>
                  <div
                    class="d-block float-start text-center ps-2"
                    style="font-size: 10px; margin-top: -5px"
                  >
                    user
                  </div>
                </div>
              </div>
              <div
                class="d-inline-block"
                style="width: 43px; padding-right: 3px"
              >
                <a
                  :href="
                    'https://fm.phys.su/autologin?h=' +
                    filteredUsers[userIndex].password
                  "
                >
                  <div
                    class="float-start btn btn-outline-primary"
                    style="padding: 0px"
                  >
                    <vue-feather
                      size="24"
                      type="log-in"
                      strokeWidth="2"
                      stroke="white"
                      fill="none"
                      style="margin-top: 10px"
                    ></vue-feather>
                    <div
                      class="d-block float-start text-center ps-1"
                      style="font-size: 10px; margin-top: -5px"
                    >
                      войти
                    </div>
                    <div
                      class="d-block float-start text-center ps-1"
                      style="font-size: 10px; margin-top: -5px"
                    >
                      как
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="d-inline-block"
                style="width: 43px; padding-right: 3px"
              >
                <div
                  class="float-start btn btn-outline-danger"
                  style="padding: 0px"
                  @click="refreshUser(userIndex)"
                  @touch="refreshUser(userIndex)"
                >
                  <vue-feather
                    size="24"
                    type="refresh-ccw"
                    strokeWidth="2"
                    stroke="white"
                    fill="none"
                    style="margin-top: 10px"
                  ></vue-feather>
                  <div
                    class="d-block float-start text-center"
                    style="font-size: 10px; margin-top: -5px; padding-left: 2px"
                  >
                    обно-
                  </div>
                  <div
                    class="d-block float-start text-center"
                    style="font-size: 10px; margin-top: -5px; padding-left: 2px"
                  >
                    вить
                  </div>
                </div>
              </div>
              <div class="d-inline-block" style="width: 40px">
                <div
                  class="float-start btn btn-outline-danger"
                  style="padding: 0px"
                  @click="deleteUser(userIndex)"
                  @touch="deleteUser(userIndex)"
                >
                  <vue-feather
                    size="24"
                    type="x"
                    strokeWidth="2"
                    stroke="white"
                    fill="none"
                    style="margin-top: 10px"
                  ></vue-feather>
                  <div
                    class="d-block float-start text-center"
                    style="
                      font-size: 10px;
                      margin-top: -5px;
                      padding-left: 8px;
                      padding-right: 0px;
                    "
                  >
                    про-
                  </div>
                  <div
                    class="d-block float-start text-center"
                    style="
                      font-size: 10px;
                      margin-top: -5px;
                      padding-left: 8px;
                      padding-right: 0px;
                    "
                  >
                    гнать
                  </div>
                </div>
              </div>









                <hr>
              </div>

              
              
            </div>
          </div>
      </div>

      <div class="ms-2 mt-5">Удалённые пользователи:</div>
      <div v-for="(user, userIndex) in filteredUsers" :key="userIndex">
        <div
          v-if="user.isDeleted == 1"
          class="w-100 mt-3"
          style="height: 65px !important"
        >
          <div
            class="d-inline-block pl-1"
            style="width: calc(100% - 175px); padding-right: 2px"
          >
            <div class="d-block">
              <div class="float-start" style="font-size: 14px">
                {{ user.phoneId }}, {{  user.chatId }}
              </div>
              <div class="float-end" style="font-size: 17px">
                <select
                  v-model="filteredUsers[userIndex].role"
                  class=""
                  name=""
                >
                  <option>admin</option>
                  <option>raider</option>
                  <option>user</option>
                </select>
              </div>
            </div>
            <div class="d-block w-100" style="font-size: 20px; float: left">
              <input class="w-100" v-model="filteredUsers[userIndex].name" />
            </div>
          </div>
          <div
            class="d-inline-block"
            style="width: 70px"
            @click="restoreUser(userIndex)"
            @touch="restoreUser(userIndex)"
          >
            <div
              class="float-start btn btn-outline-success"
              style="padding: 0px"
            >
              <vue-feather
                size="24"
                type="corner-up-left"
                strokeWidth="2"
                stroke="white"
                fill="none"
                style="margin-top: 10px"
              ></vue-feather>
              <div
                class="d-block float-start text-center"
                style="
                  font-size: 10px;
                  margin-top: -5px;
                  padding-left: 1px;
                  padding-right: 0px;
                "
              >
                Восстановить
              </div>
              <div
                class="d-block float-start text-center"
                style="
                  font-size: 10px;
                  margin-top: -5px;
                  padding-left: 1px;
                  padding-right: 0px;
                "
              >
                Пользователя
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="full_screen_loading text-center">
    <vue-feather
      size="200px"
      type="loader"
      animation="spin"
      animation-speed="slow"
    ></vue-feather>
  </div>
</template>

<script>
export default {
  name: "ControlPage",
  data() {
    return {
      user: {},
      filterField: "",
      isRefreshing: true,
      users: [],
      filteredUsers: [], // зависит от filter и users
      servicesMap: [
        {name: 'Сайкл PRO', count: 'countPro', plus: 'plusPro'},
        {name: 'Сайкл START', count: 'countStart', plus: 'plusStart'},
        {name: 'Сайкл LITE', count: 'countLite', plus: 'plusLite'},
        {name: 'Сайкл JUNIOR', count: 'countJunior', plus: 'plusJunior'},
        {name: 'Сайкл МАРАФОН', count: 'countMarafon', plus: 'plusMarafon'}
      ]
    };
  },
  mounted() {
    console.log("mounted");
    // логин по хешу. и в случае успеха там-же подгрузка списка тренировок
    this.preLoginByHash();
  },
  computed: {},
  // watch: {
  //   filter: function () {
  //
  //   }
  // },

  methods: {
    async refreshUser(filteredUsersIndex) {
      let savedUser = this.filteredUsers[filteredUsersIndex];
      console.log(savedUser);

      let response = await this.$root.axiosInstance.post("/refreshuser", {
        phoneId: savedUser.phoneId,
        name: savedUser.name,
        role: savedUser.role,
        isDeleted: 0,
        chatId: savedUser.chatId,
        birthDay: savedUser.birthDay,
        hash: this.user.password,
      });
      console.log(response);
      if (response.data.error) {
        this.$swal({
          icon: "error",
          title: "Упс...",
          text: response.data.error,
        });
        return;
      }

      let inviteText = response.data.inviteText;

      navigator.clipboard
        .writeText(inviteText)
        .then(() => {
          // Получилось!
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });

      // запомнили состояние поля фильтра
      let filterField = this.filterField;
      await this.actualizeUsers();
      await this.makeUsersFiltering();
      // восстановили состояние поля фильтра
      this.filterField = filterField;
      this.$refs["filterField"].value = filterField;
      this.$swal({
        icon: "success",
        title: "Пароль сброшен",
        text: "Текст приглашения скопирован!",
        showConfirmButton: true,
      });
    },


    async restoreUser(filteredUsersIndex) {
      let savedUser = this.filteredUsers[filteredUsersIndex];
      console.log(savedUser);

      let response = await this.$root.axiosInstance.post("/updateuser", {
        phoneId: savedUser.phoneId,
        name: savedUser.name,
        role: savedUser.role,
        passwordHash: savedUser.password,
        isDeleted: 0,
        chatId: savedUser.chatId,
        birthDay: savedUser.birthDay,
        hash: this.user.password,
      });
      console.log(response);
      if (response.data.error) {
        this.$swal({
          icon: "error",
          title: "Упс...",
          text: response.data.error,
        });
        return;
      }
      // запомнили состояние поля фильтра
      let filterField = this.filterField;
      await this.actualizeUsers();
      await this.makeUsersFiltering();
      // восстановили состояние поля фильтра
      this.filterField = filterField;
      this.$refs["filterField"].value = filterField;
      if (response.data.message)
        this.$swal({
          icon: "success",
          title: "Пользователь восстановлен",
          text: "",
          showConfirmButton: true,
        });
    },

    async deleteUser(filteredUsersIndex) {
      let savedUser = this.filteredUsers[filteredUsersIndex];
      console.log(savedUser);

      // question - текст сообщения (вопроса)
      const confirmExit = confirm(
        `Точно удалить ${savedUser.name} (${savedUser.phoneId}) ?`
      );
      if (confirmExit == false) return;

      let response = await this.$root.axiosInstance.post("/updateuser", {
        phoneId: savedUser.phoneId,
        name: savedUser.name,
        role: savedUser.role,
        passwordHash: savedUser.password,
        isDeleted: 1,
        chatId: savedUser.chatId,
        birthDay: savedUser.birthDay,
        hash: this.user.password,
      });
      console.log(response);
      if (response.data.error) {
        this.$swal({
          icon: "error",
          title: "Упс...",
          text: response.data.error,
        });
        return;
      }
      // запомнили состояние поля фильтра
      let filterField = this.filterField;
      await this.actualizeUsers();
      await this.makeUsersFiltering();
      // восстановили состояние поля фильтра
      this.filterField = filterField;
      this.$refs["filterField"].value = filterField;
      if (response.data.message)
        this.$swal({
          icon: "success",
          title: "Пользователь удалён",
          text: "Восстановить можно в списке внизу страницы",
          showConfirmButton: true,
        });
    },

    async saveCopy(filteredUsersIndex) {
      let savedUser = this.filteredUsers[filteredUsersIndex];
      // console.log(savedUser);
      navigator.clipboard
        .writeText(savedUser.invite)
        .then(() => {
          // Получилось!
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });

      let response = await this.$root.axiosInstance.post("/updateuser", {
        phoneId: savedUser.phoneId,
        name: savedUser.name,
        role: savedUser.role,
        passwordHash: savedUser.password,
        isDeleted: 0,
        chatId: savedUser.chatId,
        birthDay: savedUser.birthDay,
        hash: this.user.password,
        countPro: savedUser.countPro,
        countStart: savedUser.countStart,
        countLite: savedUser.countLite,
        countJunior: savedUser.countJunior,
        countMarafon: savedUser.countMarafon,
        plusPro: savedUser.plusPro,
        plusStart: savedUser.plusStart,
        plusLite: savedUser.plusLite,
        plusJunior: savedUser.plusJunior,
        plusMarafon: savedUser.plusMarafon,
      });
      console.log(response);
      if (response.data.error) {
        this.$swal({
          icon: "error",
          title: "Упс...",
          text: response.data.error,
        });
        return;
      }
      let filterField = this.filterField;
      await this.actualizeUsers();
      await this.makeUsersFiltering();
      // восстановили состояние поля фильтра
      this.filterField = filterField;
      this.$refs["filterField"].value = filterField;
      if (response.data.message)
        this.$swal({
          icon: "success",
          title: "Изменения применены",
          text: "Текст приглашения скопирован!",
          showConfirmButton: false,
          timer: 1500,
        });
    },



    async sendInvite(filteredUsersIndex) {

      await this.actualizeUsers();
      await this.makeUsersFiltering();

      let savedUser = this.filteredUsers[filteredUsersIndex];
      console.log(savedUser);
      

      let response = await this.$root.axiosInstance.post("/sendmessage", {
        chatId: savedUser.chatId,
        message: `Тестовое сообщение для рассылки!`,
        hash: this.user.password,
      });
      console.log(response);
      if (response.data.error) {
        this.$swal({
          icon: "error",
          title: "Упс...",
          text: response.data.error,
        });
        return;
      }
      // запомнили состояние поля фильтра
      let filterField = this.filterField;
      await this.actualizeUsers();
      await this.makeUsersFiltering();
      // восстановили состояние поля фильтра
      this.filterField = filterField;
      this.$refs["filterField"].value = filterField;
      if (response.data.message)
        this.$swal({
          icon: "success",
          title: "Отправлено!",
          text: "Приглашение отправлено через бота телеграм",
          showConfirmButton: false,
          timer: 1500,
        });
    },


    clearFilterField() {
      this.$refs["filterField"].value = "";
      this.filterField = "";
      this.makeUsersFiltering(this.filterField);
    },
    filterKeyUpHandler(e) {
      this.filterField = e.target.value;
      this.makeUsersFiltering(this.filterField);
    },
    async makeUsersFiltering() {
      // поищем совпадения в номерах телефона
      let usersFilteredByPhoneId = this.users.filter((user) => {
        return user.phoneId
          .toLowerCase()
          .includes(this.filterField.toLowerCase());
      });
      console.log(usersFilteredByPhoneId);

      // поищем совпадения в именах
      let usersFilteredByName = this.users.filter((user) => {
        return user.name.toLowerCase().includes(this.filterField.toLowerCase());
      });
      console.log(usersFilteredByName);

      if (usersFilteredByName.length > usersFilteredByPhoneId.length) {
        this.filteredUsers = usersFilteredByName;
      } else {
        this.filteredUsers = usersFilteredByPhoneId;
      }
      this.filteredUsers = this.filteredUsers.map((el, i, arr) => {
        // добавляем поля
        for (let s = 0; s < this.servicesMap.length; s++) {
          arr[i][this.servicesMap[s].plus] = 0;
        }
        return arr[i];
      })
    },
    isAdmin() {
      return (
        (this.user.role == "admin")
      );
    },

    listRedirect() {
      this.$router.push({ path: "/" });
    },
    // получить от сервера
    async actualizeUsers() {
      this.isRefreshing = true;
      // список всех пользователей
      try {
        let response = await this.$root.axiosInstance.get("/users", {
          params: { hash: this.user.password },
        });
        console.log(response);
        if (response.data.error) {
          this.$swal({
            icon: "error",
            title: "Упс...",
            text: response.data.error,
          });
          return;
        }
        this.users = response.data.users;
      } catch (e) {
        alert(e);
        console.log(e);
      }
      this.isRefreshing = false;
    },

    // --- FOR ANY PAGE METHODS
    async preLoginByHash() {
      // Получим хеш пароля пользователя из localStorage
      let passwordHash = localStorage.getItem("hash");
      console.log(passwordHash);
      if (passwordHash == null) {
        this.$router.push({ name: "LoginPage" });
        return;
      }
      try {
        let response = await this.$root.axiosInstance.post("/loginbyhash", {
          hash: passwordHash,
        });
        if (response.data.loginOk == true) {
          this.user = response.data.user;
          console.log(`Вошли по хешу, как ${this.user.name}`);

          // mounted
          if (!this.isAdmin()) { 
            alert('Вы не админ!');
            return;
          }
          await this.actualizeUsers();
          this.makeUsersFiltering();
        } else {
          // если логин по хешу неуспешный -- значит он кривой
          console.log("Ошибка во время авто-логина. Хеш будет удалён.");
          localStorage.removeItem("hash");
          this.$router.push({ name: "LoginPage" });
        }
      } catch (err) {
        alert("Сервер не отвечает \n\n" + err);
        localStorage.removeItem("hash");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
