<template>
  <div class="header text-center">
    <!-- componenetInfo -->
    <div class="d-inline-block mt-3">
      <vue-feather size="15" type="key" strokeWidth="2" stroke="white" fill="none"></vue-feather>
      Вход по пригласительной ссылке
    </div>
  </div>

</template>

<script>
export default {
  name: 'LoginPage',
  data () {
     return {
       passwordHash: ''
    }
  },
  mounted () {
    console.log('mounted');
    this.passwordHash = this.$route.query.h;
    this.loginByHashFromUrl();
  },
  computed: {
  },
  watch: {

  },

  methods: {
    async loginByHashFromUrl() {
      let passwordHash = this.passwordHash;
      console.log(passwordHash);
      if (passwordHash != null) {
        try {
          let response = await this.$root.axiosInstance.post('/loginbyhash', {hash: passwordHash});
          if (response.data.loginOk == true) {
            let user = response.data.user;
            console.log(`Вошли по хешу, как ${user.name}`);

            // после успешного логина переходим к следующему компоненту
            // Получим хеш пароля пользователя из localStorage
            localStorage.setItem('hash', passwordHash);
            this.$swal({
              position: 'top',
              icon: 'success',
              title: `Приветствую, ${user.name}!`,
              text: `Давайте ознакомимся со списком ближайших тренировок:`,
            });
            this.$router.push({ name: 'WorkoutsList' });
          }
          else {
            // если логин по хешу неуспешный -- значит он кривой
            console.log('Ошибка во время авто-логина. Хеш будет удалён.');
            localStorage.removeItem('hash');
            this.$swal({
              position: 'top',
              icon: 'error',
              title: `Ссылка устарела!`,
              text: `Запросите у тренера новую приглассительную ссылку для входа. Или войдите по логину и паролю:`,
            });
            this.$router.push({ name: 'LoginPage' });
          }
        } catch (e) {
          alert('Сервер не отвечает \n\n' + e);
          localStorage.removeItem('hash');
        }
      }
      this.autoLoginInProcess = false;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.gagarin-button{
  text-transform: uppercase;
  width: 150px;
  height: 35px;
  font-size: 13px;
  line-height: 100%;
  color: #fff;
  border:0;
  cursor: pointer;
  background: radial-gradient(50% 50% at 50% 50%, #ba2b31 0, #ab2026 100%);
}
.form-control {

}
.form-control:hover {
  border-color: rgb(248, 29, 29) !important;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: rgb(248, 29, 29) !important;
}
.form-control:active {
  box-shadow: none !important;
  border-color: rgb(248, 29, 29) !important;
}
.form-control:target {
  box-shadow: none !important;
  border-color: rgb(248, 29, 29) !important;
}
</style>
