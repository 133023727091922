<template>
  <div id="app">
    <div class="flex-container">

      <div class="flex-left">
      </div>
      <div class="flex-center-content" >
        <router-view/>
      </div>

      <div class="flex-right">
      </div>

    </div>

  </div>
</template>
<script>

import axios from "axios";
// DEV MODE !!!!!!!!
let devUrl = 'http://localhost:4096/api';
let buildUrl = 'https://fm.phys.su/api';
let serverUrl = window.location.hostname.includes('phys') ? buildUrl : devUrl;
export default {
  name: 'App',
  data () {
    return {
      serverUrl: serverUrl,
      axiosInstance: axios.create({ baseURL: serverUrl}),
    }
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style>
.cursor-pointer-wrapper {
  cursor: pointer;
}

.align-middle {
  vertical-align: middle;
}
* {
  margin: 0;
  padding: 0;
}
#app {
  min-height: 100vh;
  width: 100vw;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: white;
  background-color: #32383e;
}

.flex-container {
  display: flex;
  flex-direction: row;
}
.flex-left {
  flex-grow: 1;
  flex-shrink: 0;
  background: linear-gradient(to left, #32383e 5%, #1e2225 95%);
}
/* размер экрана меньше, чем max-width */
@media screen and (max-width: 1930px) {
  .flex-center-content {
    min-width:30vw;
  }
}
/* размер экрана меньше, чем max-width */
@media screen and (max-width: 1600px) {
  .flex-center-content {
    min-width:40vw;
  }
}
/* размер экрана меньше, чем max-width */
@media screen and (max-width: 1200px) {
  .flex-center-content {
    min-width:50vw;
  }
}
/* размер экрана меньше, чем max-width */
@media screen and (max-width: 900px) {
  .flex-center-content {
    min-width:75vw;
  }
}
@media screen and (max-width: 600px) {
  .flex-center-content {
    min-width:90vw;
  }
}
@media screen and (max-width: 400px) {
  .flex-center-content {
    min-width:100%;
    
  }
}
.flex-center-content {
  flex-grow: 0;
  flex-shrink: 1;
  background: #32383e;
  color: #fff;
}
.header {
  color: #fff;
  height: 50px;
  text-transform: uppercase;
  border-bottom: 1px solid gray;
}
.flex-right {
  flex-grow: 1;
  flex-shrink: 0;
  background: linear-gradient(to right, #32383e 5%, #1e2225 95%);
}
</style>
