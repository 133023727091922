<template >
  <div
    v-if="selectedWorkout"
    style="min-height: 100vh; position: relative"
  >
    <div class="pt-2 px-1 mb-1 my_border_bottom" style="height: 40px">
      <div class="float-left font-size-16px">

         <!-- backButton -->
        <router-link :to="success?('/w/' + dateUri) : '/'">
          <div class="d-inline-block text-danger cursor-pointer-wrapper" style="margin-right: 10px;">
            <vue-feather size="28" type="arrow-left" strokeWidth="2" stroke="#ba2b31" fill="none" class="align-middle"></vue-feather>
            <span class="align-middle">{{success ? 'К тренировке' : 'Назад'}}</span>
          </div>
        </router-link>

        
        <vue-feather
          size="15"
          type="tool"
          stroke="white"
          strokeWidth="2"
          fill="none"
        ></vue-feather>
        СОЗДАНИЕ
      </div>
    </div>

    <div class="d-block px-2 w-100" style="margin-top: 20px">
      <div class="d-inline-block" style="width: 100%">
        <div class="d-block">Название:</div>
        <div class="d-block">
          <input
            class="bg-white border px-2 py-1 rounded w-100 font-size-20px"
            type="text"
            name="workout_name"
            v-model="selectedWorkout.name"
          />
        </div>
      </div>
    </div>

    <div class="d-block px-2 w-100" style="margin-top: 20px">
      <div class="d-inline-block" style="width: 100%">
        <div class="d-block">Имя тренера:</div>
        <div class="d-block">
          <input
            class="bg-white border px-2 py-1 rounded w-100 font-size-20px"
            type="text"
            name="workout_trainer"
            v-model="selectedWorkout.trainer"
          />
        </div>
      </div>
    </div>

    <div class="d-block px-2 w-100" style="margin-top: 20px">
      <div class="d-inline-block" style="width: 100%">
        <div class="d-block">Часов до записи:</div>
        <div class="d-block">
          <input
            class="bg-white border px-2 py-1 rounded w-100 font-size-20px"
            type="text"
            name="workout_openingHours"
            v-model="selectedWorkout.openingHours"
          />
        </div>
      </div>
    </div>

    <div class="d-block px-2 w-100" style="margin-top: 20px">
      <div class="d-inline-block pl-2" style="width: 100%">
        <div class="d-block">
          <div class="d-block">Дата:</div>
          <div class="d-block">
            <input
              class="bg-white border px-2 py-1 rounded w-100 font-size-20px"
              type="text"
              v-model="pickedDate"
            />
          </div>
          <div class="d-block">Время:</div>
          <div class="d-block">
            <input
              class="bg-white border px-2 py-1 rounded w-100 font-size-20px"
              type="text"
              v-model="pickedTime"
            />
          </div>
          <!-- <v-datepicker v-model="picked" /> -->

          <!-- <vue3-datepicker
            v-model="dateId"
            color="green"
            is-dark
            :expanded="true"
            mode="dateTime"
            timezone=""
            :is24hr="true"
            :valid-hours="{ min: 7, max: 22 }"
            :minute-increment="5"
            :masks="masks"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="bg-white border px-2 py-1 rounded w-100 font-size-20px"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </vue3-datepicker> -->
        </div>
      </div>
    </div>


    
    <div class="row p-2">
      
      <div class="d-block text-center">Минимум участников:</div>
      <div class="col d-flex">
          <div
            class="btn btn-danger btn-block flex-grow-1"
            style="height: 80px; font-size: 32pt; max-width: 100%;"
            @click="decrementMinPeoples"
            @touch="decrementMinPeoples"
          >
            -
          </div>
      </div>
      <div class="col d-flex">
        <div
            class="text-center border pt-1 rounded w-100"
            style="height: 80px; font-size: 32pt"
          >
            {{ selectedWorkout.minPeoples }}
          </div>
      </div>
      <div class="col d-flex">
        <div
            class="btn btn-success btn-block flex-grow-1"
            style="height: 80px; font-size: 32pt"
            @click="incrementMinPeoples"
            @touch="incrementMinPeoples"
          >
            +
          </div>
      </div>
    </div>



    <div class="row p-2">
      
      <div class="d-block text-center">Максимальное количество мест:</div>
      <div class="col d-flex">
          <div
            class="btn btn-danger btn-block flex-grow-1"
            style="height: 80px; font-size: 32pt"
            @click="decrementMaxPlaces"
            @touch="decrementMaxPlaces"
          >
            -
          </div>
      </div>
      <div class="col d-flex">
        <div
            class="text-center border pt-1 rounded w-100 flex-grow-1"
            style="height: 80px; font-size: 32pt"
          >
            {{ selectedWorkout.maxPlaces }}
          </div>
      </div>
      <div class="col d-flex">
        <div
            class="btn btn-success btn-block flex-grow-1"
            style="height: 80px; font-size: 32pt"
            @click="incrementMaxPlaces"
            @touch="incrementMaxPlaces"
          >
            +
          </div>
      </div>
    </div>

    
    

    <div
      class="d-block px-2 float-right"
      style="width: 100%; margin-top: 20px; padding-right: 12px !important"
    >
      <div
        class="btn btn-primary btn-block pt-3"
        style="height: 80px; font-size: 22pt"
        @click="save"
        @touch="save"
      >
        <vue-feather
          v-if="!isSaving"
          size="28"
          type="save"
          strokeWidth="2"
          stroke="white"
          fill="none"
          style="margin-top: -5px"
        ></vue-feather>
        <vue-feather
          v-if="isSaving"
          size="28"
          type="loader"
          animation="spin"
          animation-speed="slow"
          strokeWidth="2"
          stroke="white"
          fill="none"
          style="margin-top: -5px"
        ></vue-feather>
        Сохранить
      </div>
    </div>
  </div>
  <div v-else class="full_screen_loading text-center">
    <vue-feather
      size="200px"
      type="loader"
      animation="spin"
      animation-speed="slow"
    ></vue-feather>
  </div>
</template>

<script>
// import { ref } from 'vue'
// import timepicker from "vue3-timepicker";
// import datepicker from "vue3-datepicker";

export default {
  name: "WorkoutCreate",
  components: {
    // timepicker,
    // datepicker,
  },
  data() {
    return {
      user: {},

      isRefreshing: false,
      isSaving: false,
      masks: {
        input: "YYYY-MM-DD mm:hh",
      },
      dateUri: "",
      oldDateId: null,
      dateId: null,
      pickedDate: null,
      pickedTime: null,
      selectedWorkout: undefined, // {}
      workoutUsers: undefined, // []
    };
  },
  mounted() {
    console.log("mounted");

    // логин по хешу. и в случае успеха там-же подгрузка списка тренировок
    this.preLoginByHash();

    // mounted
    this.selectedWorkout = {
            name: 'Сайкл PRO',
            trainer: 'Доля С.В.',
            openingHours: 24,
            minPeoples: 5,
            maxPlaces: 11,
            dateId: new Date(),
          };
          this.dateId = new Date();

    this.isRefreshing = false;
  },
  computed: {},
  watch: {
    dateId: function () {
      console.log(this.dateId);
      // при изменении this.dateId в компоненте v-date-picker
      // он становится Date, который мы переведём в привычный для mysql формат
      // например: 2022-03-28 07:15:00
      if (this.dateId instanceof Date) {
        this.selectedWorkout.dateId = this.getDateIdFromInstanceOfDate(this.dateId);
        this.dateUri = this.getDateUriFromDateId(this.selectedWorkout.dateId);
        let date = this.dateUri.split("_")[1];
        let time = this.dateUri.split("_")[0];
        date = date.split("-").reverse().join("-");
        time = time.replaceAll("-", ":") + ":00";
        this.pickedDate = date;
        this.pickedTime = time;
        this.dateId = date + " " + time;
        this.oldDateId = date + " " + time;
      }
    },
    pickedDate: function () {
      try {
        this.dateId = this.pickedDate + " " + this.pickedTime;
        this.selectedWorkout.dateId = this.dateId;
        const dateParts = this.pickedDate.split('-');
        const timeParts = this.pickedTime.split(':');

        // Форматируем строку в нужном порядке
        this.dateUri = `${timeParts[0]}-${timeParts[1]}_${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      }
      // eslint-disable-next-line no-empty
      catch (e) {

      }
      
    },
    pickedTime: function () {
      try {
        this.dateId = this.pickedDate + " " + this.pickedTime;
        this.selectedWorkout.dateId = this.dateId;
        const dateParts = this.pickedDate.split('-');
        const timeParts = this.pickedTime.split(':');

        // Форматируем строку в нужном порядке
        this.dateUri = `${timeParts[0]}-${timeParts[1]}_${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      }
      // eslint-disable-next-line no-empty
      catch (e) {

      }
    },
  },

  methods: {
    isAdmin() {
      return (
        (this.user.role == "admin")
      );
    },
    decrementMinPeoples() {
      if (this.selectedWorkout.minPeoples < 2) return;
      this.selectedWorkout.minPeoples--;
    },
    incrementMinPeoples() {
      this.selectedWorkout.minPeoples++;
    },
    decrementMaxPlaces() {
      if (this.selectedWorkout.maxPlaces < 2) return;
      this.selectedWorkout.maxPlaces--;
    },
    incrementMaxPlaces() {
      this.selectedWorkout.maxPlaces++;
    },
    getDateIdFromInstanceOfDate (instOfDate) {
      instOfDate.setSeconds(0);
      let localeString = instOfDate.toLocaleString("ru-RU", {timeZone: "Europe/Moscow"});
      let dtArr = localeString.split(', ');
      let date = dtArr[0].split('.').reverse().join('-');
      let time = dtArr[1];
      return date + ' ' + time;
    },
    getDateUriFromDateId (dateIdStr) {
      // get dateUri
      let date = dateIdStr.split(' ')[0].split('-').reverse().join('-');
      let time = dateIdStr.split(' ')[1];
      time = time.split(':')[0] + '-' + time.split(':')[1]; // 07:15:00 => 07:15
      return time + '_' + date;
    },
    async save () {
      this.isSaving = true;

      let params = {
        dateId: this.selectedWorkout.dateId,
        name: this.selectedWorkout.name,
        trainer: this.selectedWorkout.trainer,
        openingHours: this.selectedWorkout.openingHours,
        minPeoples: this.selectedWorkout.minPeoples,
        maxPlaces: this.selectedWorkout.maxPlaces,
        hash: this.user.password
      };
      let response = await this.$root.axiosInstance.post('/workoutcreate', params);
      if (response.data.error) {
        this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
        return;
      }

      this.isSaving = false;
      this.success = true;
      this.$swal({
        position: 'top',
        icon: 'info',
        title: 'Тренировка добавлена',
        text: `Тренировка ${this.selectedWorkout.dateId} успешно создана. Нажмите срелку назад, чтобы перейти к ней.`,
        timer: 5000
      });
    },

    // --- FOR ANY PAGE METHODS
    async preLoginByHash() {
      // Получим хеш пароля пользователя из localStorage
      let passwordHash = localStorage.getItem("hash");
      console.log(passwordHash);
      if (passwordHash == null) {
        this.$router.push({ name: "LoginPage" });
        return;
      }
      try {
        let response = await this.$root.axiosInstance.post("/loginbyhash", {
          hash: passwordHash,
        });
        if (response.data.loginOk == true) {
          this.user = response.data.user;
          console.log(`Вошли по хешу, как ${this.user.name}`);

          // если неадмин вдруг попал сюда -- редирект домой
          if (!this.isAdmin()) this.$router.push({ name: "WorkoutsList" }); 
        } else {
          // если логин по хешу неуспешный -- значит он кривой
          console.log("Ошибка во время авто-логина. Хеш будет удалён.");
          localStorage.removeItem("hash");
          this.$router.push({ name: "LoginPage" });
        }
      } catch (err) {
        alert("Сервер не отвечает \n\n" + err);
        localStorage.removeItem("hash");
      }
    },
    logout() {
      // question - текст сообщения (вопроса)
      const confirmExit = confirm("Точно выйти?");
      if (confirmExit == false) return;
      localStorage.removeItem("hash");
      location.reload();
    },
    // показать, скрыть
    showHideUserInfo(e) {
      e.stopPropagation();
      this.isVisibleUserInfo = !this.isVisibleUserInfo; // if (e.target.closest('#userInfoButton') != undefined)
    },
    // возвращает относительную строку
    getRelStr(dateTime) {
      // вспомогательная функция. находит разницу между двумя дата-временами в днях. часах, минутах
      function getRel(dt1, dt2) {
        let durationMs = dt1 - dt2;
        durationMs /= 1000;
        durationMs /= 60;
        let minutes = Math.ceil(durationMs % 60);
        durationMs /= 60;
        let hours = Math.ceil(durationMs % 24);
        durationMs /= 24;
        let days = Math.ceil(durationMs);
        return {
          days: days,
          hours: hours,
          minutes: minutes,
        };
      }
      let result = "";
      // для того, чтобы понять разницу в днях -- измерять нужно от сегодняшней полуночи
      let dt00 = new Date();
      dt00.setHours(0, 0, 0, 0);
      let dt00Rel = getRel(new Date(dateTime), dt00);

      // если сегодня, то нужно относительно текущего момента вычислить hh:mm
      if (dt00Rel.days === 1) {
        // console.log('СЕГОДНЯ: ');
        let dtRel = getRel(new Date(dateTime), new Date());

        // console.log(`hours ${dtRel.hours}, minutes ${dtRel.minutes}`);
        if (dtRel.hours < 0 || dtRel.minutes < 0) {
          result =
            (dtRel.hours !== 0 ? -1 * dtRel.hours + " ч. " : "") +
            "" +
            -1 * dtRel.minutes +
            " мин. назад";
        } else {
          result =
            "через " +
            (dtRel.hours !== 0 ? dtRel.hours + " ч. " : "") +
            "" +
            dtRel.minutes +
            " мин.";
        }
      }
      if (dt00Rel.days <= -8) result = "> 9 дней назад";
      if (dt00Rel.days === -7) result = "8 дней назад";
      if (dt00Rel.days === -6) result = "7 дней назад";
      if (dt00Rel.days === -5) result = "6 дней назад";
      if (dt00Rel.days === -4) result = "5 дней назад";
      if (dt00Rel.days === -3) result = "4 дня назад";
      if (dt00Rel.days === -2) result = "3 дня назад";
      if (dt00Rel.days === -1) result = "позавчера";
      if (dt00Rel.days === 0) result = "вчера";
      if (dt00Rel.days === 2) result = "завтра";
      if (dt00Rel.days === 3) result = "послезавтра";
      if (dt00Rel.days === 4) result = "через 3 дня";
      if (dt00Rel.days === 5) result = "через 4 дня";
      if (dt00Rel.days === 6) result = "через 5 дней";
      if (dt00Rel.days === 7) result = "через 6 дней";
      if (dt00Rel.days === 8) result = "через 7 дней";
      if (dt00Rel.days === 9) result = "через 8 дней";
      if (dt00Rel.days >= 10) result = "через > 9 дней";

      console.log(result);
      return result;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.my_border_bottom {
  border-bottom: 1px solid gray;
}
.my_border_top {
  border-top: 1px solid gray;
}
.font-size-10px {
  font-size: 10px;
}
.font-size-12px {
  font-size: 12px;
}
.font-size-14px {
  font-size: 14px;
}
.font-size-16px {
  font-size: 16px;
}
.font-size-18px {
  font-size: 18px;
}
.font-size-20px {
  font-size: 20px;
}
.font-size-22px {
  font-size: 22px;
}
.font-size-24px {
  font-size: 24px;
}
.font-size-26px {
  font-size: 26px;
}
.font-size-28px {
  font-size: 28px;
}
.font-size-30px {
  font-size: 30px;
}
.full_screen_loading {
  background-color: #32383e;
  height: calc(100vh - 50px);
}
</style>
