<template>
  <div class="header text-center">
    <!-- componenetInfo -->
    <div class="d-inline-block mt-3">
      <vue-feather size="15" type="key" strokeWidth="2" stroke="white" fill="none"></vue-feather>
      Вход
    </div>
  </div>

  <a href="https://t.me/fm_padel_bot" style="text-decoration: none;">
      <div v-if="autoLoginInProcess == false">
        <div class="container d-flex justify-content-center mt-2">
          <div class="d-block">
            Вы попали на страницу записи на сайкл/теннис. 
            Внимание, вход только по приглашению!
            Для получения ссылки обратитесь к телеграм-боту.
          </div>
      </div>

      <div class="container d-flex justify-content-center mt-2">
          <br>
          <div style="width: 80%;">
            <img src="/qr_bot.svg" alt="My SVG Icon" style="width:100%"/>
          </div>
      </div>
      <div class="container d-flex justify-content-center mt-2">
        <h1>https://t.me/fm_padel_bot</h1>
      </div>
  
    </div>
  </a>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
     return {
       inputLogin: '',
       inputPassword: '',
       inputRemeberMe: true,
       autoLoginInProcess: true,
    }
  },
  mounted () {
    console.log('mounted');
    this.loginByHash();
  },
  computed: {
  },
  watch: {

  },

  methods: {
    async login (e) {
      e.preventDefault();
      try {
        let response = await this.$root.axiosInstance.post('/login', {inputLogin: this.inputLogin, inputPassword: this.inputPassword});
        if (response.data.loginOk == false) {
          this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
          return;
        }
        let user = response.data.user;

        // Запишем хеш пароля пользователя в localStorage
        localStorage.setItem('hash', user.password);

        this.$emit('update:user', user);
        console.log(`Вошли, как ${user.name}`);

        // после успешного логина переходим к следующему компоненту
        this.$router.push({ name: 'WorkoutsList' });


      } catch (err) {
        alert('Сервер не отвечает \n\n' + err);
        console.log('Сервер не отвечает \n\n');
      }
    },
    async loginByHash() {
      // Получим хеш пароля пользователя из localStorage
      let passwordHash = localStorage.getItem('hash');
      console.log(passwordHash);
      if (passwordHash != null) {
        try {
          let response = await this.$root.axiosInstance.post('/loginbyhash', {hash: passwordHash});
          if (response.data.loginOk == true) {
            let user = response.data.user;
            console.log(`Вошли по хешу, как ${user.name}`);

            // после успешного логина переходим к следующему компоненту
            this.$router.push({ name: 'WorkoutsList' });
          }
          else {
            // если логин по хешу неуспешный -- значит он кривой
            console.log('Ошибка во время авто-логина. Хеш будет удалён.');
            localStorage.removeItem('hash');
          }
        } catch (e) {
          alert('Сервер не отвечает \n\n' + e);
          localStorage.removeItem('hash');
        }
      }
      this.autoLoginInProcess = false;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.gagarin-button{
  text-transform: uppercase;
  width: 150px;
  height: 35px;
  font-size: 13px;
  line-height: 100%;
  color: #fff;
  border:0;
  cursor: pointer;
  background: radial-gradient(50% 50% at 50% 50%, #ba2b31 0, #ab2026 100%);
}
.form-control:hover {
  border-color: rgb(248, 29, 29) !important;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: rgb(248, 29, 29) !important;
}
.form-control:active {
  box-shadow: none !important;
  border-color: rgb(248, 29, 29) !important;
}
.form-control:target {
  box-shadow: none !important;
  border-color: rgb(248, 29, 29) !important;
}
</style>
