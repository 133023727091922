<template >
  <div
  v-if="!isRefreshing"
  @touch="isVisibleUserInfo = false"
  @click="isVisibleUserInfo = false"
  style="min-height: 100vh;"
  >
    <div class="header">


      <div class="d-flex align-items-center justify-content-between my_border_bottom font-size-18px mb-1">
        <!-- Левый блок -->
        <div class="d-inline-block" style="margin-right: 10px;" @click="listRedirect" @touch="listRedirect">

          <vue-feather size="15" type="activity" strokeWidth="2" stroke="white" fill="none"></vue-feather>

            <div v-if="!isAdmin()" class="d-inline ms-1">
              Список тренировок
            </div>
        
        <div v-if="isAdmin()" class="ms-2 d-inline-block btn btn-sm btn-secondary"
        @click="createRedirect"
        @touch="createRedirect"
        >
          <vue-feather size="13" type="plus" strokeWidth="2" stroke="white" fill="none"></vue-feather>
          Занятие
        </div>

        <div v-if="isAdmin()" class="ms-2 d-inline-block btn btn-sm btn-secondary" 
        @click="controlRedirect"
        @touch="controlRedirect"
        >
          <vue-feather size="13" type="settings" strokeWidth="2" stroke="white" fill="none"></vue-feather>
          АДМИНИТЬ
        </div>
      </div>
        <!-- Правый блок -->
        <div class="d-inline-block float-right" style="cursor: pointer;" id="userInfoButton" @click="showHideUserInfo($event)" @touch="showHideUserInfo($event)">
          <div class="d-inline-block" style="">
            <vue-feather size="40" type="user" strokeWidth="2" stroke="#ba2b31" fill="none"></vue-feather>
          </div>
          <div class="d-inline-block"  style="margin-left:-10px;">
            <vue-feather size="30" type="chevron-down" strokeWidth="2" stroke="#ba2b31" fill="none"></vue-feather>
          </div>
        </div>
      </div>



      <!-- componenetInfo -->


      <!-- userInfo -->


      <div style="position: relative;" v-if="isVisibleUserInfo">
        <div class="" style="position:absolute; width:200px; right:5px; top:10px;  border: 1px solid green; background-color:black; box-shadow: 0 0 5px 2px;">
          <div class="d-inline-block ms-3">
            <div class="d-block">
              <h4 class="d-inline mb-0">
                {{user.name}}
              </h4>
            </div>
            <div class="d-block" style="font-size:10pt;">
              {{user.phoneId}}
            </div>
            <div class="d-block" style="font-size:10pt;">
              Сайкл PRO: {{user.countPro}}
            </div>
            <div class="d-block" style="font-size:10pt;">
              Сайкл START: {{user.countStart}}
            </div>
            <div class="d-block" style="font-size:10pt;">
              Сайкл LITE: {{user.countLite}}
            </div>
            <div class="d-block" style="font-size:10pt;">
              Сайкл JUNIOR: {{user.countJunior}}
            </div>
            <div class="d-block" style="font-size:10pt;">
              Сайкл МАРАФОН: {{user.countMarafon}}
            </div>
            <div class="d-block" style="font-size:10pt;" @click="logout" @touch="logout">
              <vue-feather size="45" type="log-out" strokeWidth="2" stroke="#ba2b31" fill="none"></vue-feather>
              <div style="display:inline; font-size: 20pt;">
                Выйти
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="list.length > 0">
      <div
      class="d-block"
      v-for="(item, index) in list"
      :key="index"
      @click="selectWorkout(item.dateId)"
      @touch="selectWorkout(item.dateId)"
      >
        <div class="d-block">
          <div v-if="index == 0" class=" ms-2 mt-3">
            Последняя:
          </div>
          <div v-else-if="index == list.findIndex(fi => fi.isLessThanOneHour)" class="ms-2 mt-4">
            Скоро начнётся: <div class="d-inline text-secondary"> &lt; 4 часов до старта</div>
          </div>
          <div v-else-if="index == list.findIndex(fi => !fi.isRecordNotOpenYet && !fi.isTrainingOver && !fi.isLessThanOneHour)" class="ms-2 mt-4">
            Ближайшая: <div class="d-inline text-secondary"> &lt; 24 часа до старта</div>
          </div>
          <div v-else-if="index == list.findIndex(fi => fi.isRecordNotOpenYet)" class="ms-2 mt-5">
            Планируется:
          </div>
        </div>

        <table :class="item.isCanceled ? 'd-block table-canceled' : 'd-block'">
          <!-- <tr>
            <th scope="col" style="min-width:162px;">Тренировка</th>
            <th scope="col" style="min-width:145px;">Время</th>
            <th scope="col" style="min-width:75px;">Мест</th>
          </tr> -->
          <div class="tr-div">

            <!-- ЛЕВЫЙ СТОЛБЕЦ -->
            <div style="min-width:100px; font-weight: bold; " class="td-div" >
              <div class="d-block" style="white-space: nowrap; overflow: hidden; text-overflow: clip;">
                <div v-if="item.isOurUser" class="d-inline-block me-1 text-success">
                  <vue-feather size="12" type="check-circle" strokeWidth="2" fill="none"></vue-feather>
                </div>
                <div v-else class="d-inline-block me-1 text-secondary">
                  <vue-feather size="12" type="circle" strokeWidth="2" fill="none"></vue-feather>
                </div>
                <div :class="item.isCanceled ? 'd-inline-block text-secondary' : item.isRecordNotOpenYet ? 'd-inline-block text-secondary' : item.peoples >= item.minPeoples ? 'd-inline-block text-success' : 'd-inline-block text-warning'"
                style="font-size:14px; margin-bottom:5px;"
                >
                  {{item.name}}
                </div>
              </div>

              <div v-if="item.isCanceled" class="d-block pt-1" style="font-size: 10px;">
                <div class="d-inline">
                  <vue-feather size="12" type="lock" stroke="red" strokeWidth="2" fill="none"></vue-feather>
                </div>
                <div class="d-inline text-secondary">
                  ОТМЕНЕНА
                </div>
              </div>
              <div v-else class="d-block pt-1" style="font-size: 10px;">
                <div v-if="(item.isRecordNotOpenYet)" class="d-block">
                  <div class="d-inline">
                    <vue-feather size="12" type="lock" stroke="#6c757d" strokeWidth="2" fill="none"></vue-feather>
                  </div>
                  <div class="d-inline text-secondary">
                    Закрыта
                  </div>
                </div>
                <div v-else-if="(item.isLessThanOneHour)" class="d-block">
                  <div class="d-inline">
                    <vue-feather size="12" type="unlock" stroke="green" strokeWidth="2" fill="none"></vue-feather>
                  </div>
                  <div class="d-inline text-secondary">
                    Донабор
                  </div>
                </div>
                <div v-else-if="(item.isTrainingOver)" class="d-block">
                  <div class="d-inline">
                    <vue-feather size="12" type="lock" stroke="red" strokeWidth="2" fill="none"></vue-feather>
                  </div>
                  <div class="d-inline text-secondary">
                    Завершена
                  </div>
                </div>
                <div v-else class="d-block">
                  <div class="d-inline">
                    <vue-feather size="12" type="unlock" stroke="green" strokeWidth="2" fill="none"></vue-feather>
                  </div>
                  <div class="d-inline text-secondary">
                    Открыта
                  </div>
                </div>
              </div>
            </div>
            <!-- <td>
                {{item.day}}<br>
                {{item.date}}
                {{item.month}},
                ({{getRelStr(item.dateId)}})
            </td> -->

            <!-- Центральный столбец -->
            <div class="td-div" style="max-width: 60px; padding:0;" >
              <div class="d-block">
                <div class="d-inline me-1">
                  {{item.time}}
                </div>
                <div class="d-inline" style="font-size:14px;">
                  {{shortenDayOfWeek(item.day)}}
                </div>
              </div>
              <div class="d-block text-secondary pt-1 text-nowrap" style="font-size:14px;">
                {{getRelStr(item.dateId)}}
              </div>
            </div>

            <!-- ПРАВЫЙ СТОЛБЕЦ -->
            <div style="float:right; padding-left: 0px !important;padding-right: 0px !important;" class="td-div text-nowrap">

              <div class="d-block">
                {{ item.trainer.split(' ')[0] }}
              </div>

              <div v-if="!item.isRecordNotOpenYet" class="d-block" :class="item.isCanceled ? 'text-secondary' : item.peoples >= item.minPeoples ? 'text-success' : 'text-warning'">
                <vue-feather size="15" type="users" strokeWidth="2" fill="none"
                :stroke="item.isCanceled ? '#6c757d' : item.peoples >= item.minPeoples ? '#28a745' : '#ffc107'"
                ></vue-feather>
                {{item.peoples}} / {{item.maxPlaces}}
              </div>
              <!-- <div v-if="!item.isCanceled" class="">
                <div class="d-block mt-1 text-secondary" style="font-size:12px;">
                  {{item.peoples >= item.minPeoples?'есть группа':'мало людей'}}
                </div>
              </div>
              <div v-else class="">
                <div class="d-block mt-1 text-secondary" style="font-size:14px;">
                  отменена
                </div>
              </div> -->
            </div>
          </div>
        </table>
      </div>

    </div>
  </div>
  <div v-else class="full_screen_loading text-center">
    <vue-feather size="200px" type="loader" animation="spin" animation-speed="slow"></vue-feather>
  </div>

</template>

<script>
export default {
  name: 'WorkoutsList',
  data () {
     return {
       user: {},
       isVisibleUserInfo: false,

       isRefreshing: true,
       list: [],
    }
  },
  mounted () {
    console.log('mounted');
    // логин по хешу. и в случае успеха там-же подгрузка списка тренировок
    this.preLoginByHash();
  },
  computed: {
  },
  watch: {
    //   workoutUsers: function () {
    //
    //   },
  },

  methods: {
    shortenDayOfWeek(fullDay) {
        const daysOfWeek = {
            "понедельник": "пн",
            "вторник": "вт",
            "среда": "ср",
            "четверг": "чт",
            "пятница": "пт",
            "суббота": "сб",
            "воскресенье": "вс"
        };

        const normalizedDay = fullDay.toLowerCase();
        return daysOfWeek[normalizedDay] || fullDay;
    },
    isAdmin () {
      return (this.user.role=='admin');
    },

    controlRedirect () {
      this.$router.push({ path: '/control'});
    },
    createRedirect () {
      this.$router.push({ path: '/create' });
    },
    // получить от сервера
    async actualizeWorkouts () {
      this.isRefreshing = true;
      // список всех тренировок
      try {
        await this.$root.axiosInstance.get('/schedulecheck', {params:{hash: this.user.password}});
        let count = (this.user.role=='admin') ? 20 : 10;
        let response = await this.$root.axiosInstance.get('/lastworkouts', {params:{count: count, ourUserPhoneId: this.user.phoneId, hash: this.user.password}});
        if (response.data.error) {
          this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
          return;
        }
        this.list = response.data;
      } catch (e) {
        alert(e);
        console.log(e);
      }
      this.isRefreshing = false;
    },
    // КЛИК (Выбор занятия)
    selectWorkout (dateId) {
      // передаём родителю APP информацию о выбранной тренировке, чтобы он смог запустить WorkoutPage
      // this.$emit('update:selectedWorkout', selectedWorkout);
      console.log(dateId);
      let date = dateId.split(' ')[0].split('-').reverse().join('-');
      let time = dateId.split(' ')[1];
      time = time.split(':')[0] + '-' + time.split(':')[1]; // 07:15:00 => 07:15
      let datetime = time + '_' + date;
      console.log(`Выбрана тренировка ${datetime}`);
      this.$router.push({ path: `/w/${datetime}`});
    },

    // --- FOR ANY PAGE METHODS
    async preLoginByHash() {
      // Получим хеш пароля пользователя из localStorage
      let passwordHash = localStorage.getItem('hash');
      console.log(passwordHash);
      if (passwordHash == null) {
        this.$router.push({ name: 'LoginPage' });
        return;
      }
      try {
        let response = await this.$root.axiosInstance.post('/loginbyhash', {hash: passwordHash});
        if (response.data.loginOk == true) {
          this.user = response.data.user;
          console.log(`Вошли по хешу, как ${this.user.name}`);

          // mounted обновление списка тренировок!
          this.actualizeWorkouts();
        }
        else {
          // если логин по хешу неуспешный -- значит он кривой
          console.log('Ошибка во время авто-логина. Хеш будет удалён.');
          localStorage.removeItem('hash');
          this.$router.push({ name: 'LoginPage' });
        }
      } catch (err) {
        alert('Сервер не отвечает \n\n' + err);
        localStorage.removeItem('hash');
      }
    },
    logout () {
      // question - текст сообщения (вопроса)
      const confirmExit = confirm('Точно выйти?');
      if (confirmExit == false) return;
      localStorage.removeItem('hash');
      location.reload();
    },
    // показать, скрыть
    showHideUserInfo (e) {
      e.stopPropagation();
      this.isVisibleUserInfo = !this.isVisibleUserInfo; // if (e.target.closest('#userInfoButton') != undefined)
    },
    // возвращает относительную строку
    getRelStr (dateTime) {
        // вспомогательная функция. находит разницу между двумя дата-временами в днях. часах, минутах
        function getRel(dt1, dt2) {
            let durationMs = (dt1 - dt2)
            durationMs /= 1000;
            durationMs /= 60;
            let minutes = Math.ceil((durationMs) % 60);
            durationMs /= 60;
            let hours = Math.ceil((durationMs) % 24);
            durationMs /= 24;
            let days = Math.ceil((durationMs));
            return {
                days: days,
                hours: hours,
                minutes: minutes
            };
        }
        let result = '';
        // для того, чтобы понять разницу в днях -- измерять нужно от сегодняшней полуночи
        let dt00 = new Date();
        dt00.setHours(0,0,0,0);
        let dt00Rel = getRel(new Date(dateTime), dt00);

        // если сегодня, то нужно относительно текущего момента вычислить hh:mm
        if (dt00Rel.days === 1) {
            // // console.log('СЕГОДНЯ: ');
            // let dtRel = getRel(new Date(dateTime), new Date());
            //
            // // console.log(`hours ${dtRel.hours}, minutes ${dtRel.minutes}`);
            // if (dtRel.hours < 0 || dtRel.minutes < 0) {
            //     result = (dtRel.hours !== 0 ? (-1) * dtRel.hours + 'ч. ' : '') + '' + (-1) * dtRel.minutes + 'м. назад'
            // }
            // else {
            //     result = 'через ' + (dtRel.hours !== 0 ? dtRel.hours + 'ч. ' : '') + '' + dtRel.minutes + 'м.'
            // }
            result = 'сегодня';
        }
        if (dt00Rel.days <= -8) result = '> 9 дней назад'
        if (dt00Rel.days === -7) result = '8 дней назад'
        if (dt00Rel.days === -6) result = '7 дней назад'
        if (dt00Rel.days === -5) result = '6 дней назад'
        if (dt00Rel.days === -4) result = '5 дней назад'
        if (dt00Rel.days === -3) result = '4 дня назад'
        if (dt00Rel.days === -2) result = '3 дня назад'
        if (dt00Rel.days === -1) result = 'позавчера'
        if (dt00Rel.days === 0) result = 'вчера'
        if (dt00Rel.days === 2) result = 'завтра'
        if (dt00Rel.days === 3) result = 'послезавтра'
        if (dt00Rel.days === 4) result = 'через 3 дня'
        if (dt00Rel.days === 5) result = 'через 4 дня'
        if (dt00Rel.days === 6) result = 'через 5 дней'
        if (dt00Rel.days === 7) result = 'через 6 дней'
        if (dt00Rel.days === 8) result = 'через 7 дней'
        if (dt00Rel.days === 9) result = 'через 8 дней'
        if (dt00Rel.days >= 10) result = 'через > 9 дней'

        console.log(result);
        return result
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  border-top:0px!important;
  background-color: #22282e;
  border-radius: 20px;
  min-height: 76px;
  margin-bottom: 10px;
}
.table-canceled {
  background-color: #30282e;
}
.tr-div {
  width:100%;
}
table:hover {
  background-color: black;
  cursor: pointer;
}
.td-div {
    display: inline-block;
    padding-right:5px!important;
    padding-left:5px!important;
    padding-top:15px!important;
    padding-bottom:15px!important;
    width: calc(100% / 3);
}
</style>
