import { createWebHistory, createRouter } from "vue-router";
import LoginPage from "@/components/LoginPage.vue";
import WorkoutsList from "@/components/WorkoutsList.vue";
import WorkoutPage from "@/components/WorkoutPage.vue";
import WorkoutEdit from "@/components/WorkoutEdit.vue";
import WorkoutCreate from "@/components/WorkoutCreate.vue";
import AutoLogin from "@/components/AutoLogin.vue";
import ControlPage from "@/components/ControlPage.vue";
import ErrorPage404 from "@/components/ErrorPage404.vue";
import ScheduleEditPage from "@/components/ScheduleEditPage.vue";

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/",
    name: "WorkoutsList",
    component: WorkoutsList,
  },
  {
    path: "/create",
    name: "WorkoutCreate",
    component: WorkoutCreate,
  },
  {
    path: "/w/:datetime",
    name: "WorkoutPage",
    component: WorkoutPage,
  },
  {
    path: "/we/:datetime",
    name: "WorkoutEdit",
    component: WorkoutEdit,
  },
  {
    path: "/autologin",
    name: "AutoLogin",
    component: AutoLogin,
  },
  {
    path: "/control",
    name: "ControlPage",
    component: ControlPage,
  },
  {
    path: "/404",
    name: "ErrorPage404",
    component: ErrorPage404,
  },
  {
    path: "/schedule",
    name: "ScheduleEditPage",
    component: ScheduleEditPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
