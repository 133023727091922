<template>
    <div
    v-if="selectedWorkout && workoutUsers"
    @touch="isVisibleUserInfo = false"
    @click="isVisibleUserInfo = false"
    style="min-height: 100vh; position:relative;"
    >

    <div v-if="selectedWorkout.isTrainingOver" class="">
      <!-- <div class="mx-1" style="background-color: #e91e63; padding:15px; font-size: 20px;  border-radius: 5px;" role="alert"> -->
      <div class="alert alert-danger" role="alert">
        {{`Запись уже закрыта.`}}
      </div>
    </div>

    <!-- <div v-if="selectedWorkout.isRecordNotOpenYet" class="">
      <div class="alert alert-danger" role="alert">
        {{`Запись ещё не открыта.`}}
      </div>
    </div> -->

    <div v-if="selectedWorkout.isLessThanOneHour" class="">
      <div class="alert alert-warning" role="alert">
        {{`Осталось менее 4 часов до начала тренировки. Снятие брони отключено!`}}
      </div>
    </div>


    <div class="d-flex align-items-center justify-content-between my_border_bottom font-size-18px mb-1" style="height: 40px;">
      <!-- Левый блок -->
      <div class="d-inline-block text-danger cursor-pointer-wrapper" style="margin-right: 10px;" @click="listRedirect" @touch="listRedirect">
        <vue-feather size="28" type="arrow-left" strokeWidth="2" stroke="#ba2b31" fill="none" class="align-middle"></vue-feather>
        <span class="align-middle">Назад</span>
      </div>

      <!-- Средний блок -->
      <div class="text-center">
        <vue-feather size="15" type="clock" strokeWidth="2" fill="none"></vue-feather>
        {{selectedWorkout.time}} {{selectedWorkout.day}} {{selectedWorkout.dateAndMonth}}
      </div>

      <!-- Правый блок -->
      <div class="">
        <vue-feather v-if="!isRefreshing" size="15" type="users" strokeWidth="2" fill="none"></vue-feather>
        <vue-feather v-if="isRefreshing" size="15" type="loader" strokeWidth="2" fill="none" animation="spin" animation-speed="fast"></vue-feather>
        {{workoutUsers.length}} / {{selectedWorkout.maxPlaces}}
      </div>
    </div>

    


      <div class="" style="height: 100px; position:relative;">
        <div
        style="position:absolute; transform: rotate(90deg); top:15px; left:2px;"
        @click="dynamic"
        @touch="dynamic"
        >
          <vue-feather style="" size="35" type="volume-2" strokeWidth="2" stroke="gray" fill="none"></vue-feather>
        </div>
        <div class="d-inline-block text-center" style="width:100%;">
          <div
          class="pt-1 d-inline-block text-center"
          style="width: 83%; max-width:calc(100%-100px);
            border-top: 120px solid black;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;"
          @click="ekran"
          @touch="ekran"
          >
              <div class="" style="margin-top:-115px; line-height: 17px;">
                {{selectedWorkout.name}}
                <div class="mt-2">
                  <div v-if="enoughWorkoutUsers()" class="text-success">
                    Тренировка состоится
                  </div>
                  <div v-else class="text-warning font-size-12px">
                    <vue-feather size="11" type="alert-circle" strokeWidth="2" fill="none"></vue-feather>
                    Минимум {{selectedWorkout.minPeoples}} участников
                    <vue-feather size="11" type="alert-circle" strokeWidth="2" fill="none"></vue-feather>
                  </div>

                  <div v-if="!selectedWorkout.isCanceled" class="mt-1">
                    <div v-if="thisUserReservedPlaceNumber!==undefined" class="text-success font-size-12px">
                      <vue-feather size="15" type="check-circle" strokeWidth="2" fill="none"></vue-feather>
                      ВЫ ЗАПИСАНЫ - МЕСТО: {{thisUserReservedPlaceNumber}}
                    </div>
                    <div v-if="thisUserReservedPlaceNumber==undefined" class="text-warning font-size-14px">
                      {{selectedPlaceNumber==undefined?'Выберите место':('Выбранное место: ' + selectedPlaceNumber)}}
                    </div>
                  </div>
                  <div v-else class="mt-1">
                    <div class="text-danger font-size-14px">
                      ТРЕНИРОВКА ОТМЕНЕНА
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div
        style="position:absolute; transform: rotate(90deg); top:15px; right:5px;"
        @click="dynamic"
        @touch="dynamic"
        >
          <vue-feather size="35" type="volume-2" strokeWidth="2" stroke="gray" fill="none"></vue-feather>
        </div>
      </div>

      <div
      class="text-center trainer"
      :style="(selectedWorkout.isLessThanOneHour || selectedWorkout.isTrainingOver) ? 'top: 225px;' : 'top: 150px;'"
      >
        <div class="text-center"
        @click="bestTrainer"
        @touch="bestTrainer"
        >
          <vue-feather size="25" type="user" strokeWidth="2" fill="none"></vue-feather>
          <div class="text-success font-size-16px">{{ selectedWorkout.trainer }}</div>
        </div>
      </div>

      <div class="table_container" style="padding:0; padding-bottom:20px; width: 100%">
        <table v-for="colls in places" :key="colls" class="text-center mb-0 w-100">
          <tbody>
            <tr>
              <td
              v-for="(col, colIndex) in colls"
              :key="colIndex"
              @click="selectPlace(col.bikeNumber)"
              @touch="selectPlace(col.bikeNumber)"
              >
                <div
                :class="getPlaceContainerClass(col)"
                :style="'margin-top:' + col.marginTop + 'px'"
                >
                  <!-- занято не текущим пользователем -->
                  <div v-if="col.user && col.user.phoneId != user.phoneId">
                    <img src="/other.png" class="icon">
                    <div class="place_label">
                      №: {{col.bikeNumber}}
                    </div>
                    <div style="font-size:0.7em; overflow: hidden; text-overflow: clip;">
                      {{col.user.name}}
                    </div>
                  </div>
                  <!-- занято текущим пользователем -->
                  <div v-else-if="col.user && col.user.phoneId == user.phoneId">
                    <img src="/you.png" class="icon">
                    <div class="place_label" style="font-size:0.7em; overflow: hidden; text-overflow: clip;">
                      {{user.name}}
                    </div>
                    <button
                    class="btn btn-sm btn-block btn-danger place_button"
                    @click="deleteWorkoutUser()"
                    @touch="deleteWorkoutUser()"
                    >
                      Х
                    </button>
                  </div>
                  <!-- место свободно и выбрано -->
                  <div v-else-if="selectedPlaceNumber == col.bikeNumber">
                    <img src="/you.png" class="icon">
                    <div class="place_label">
                      №: {{col.bikeNumber}}
                    </div>
                    <div class="text-success font-size-12px">
                      занять
                    </div>
                    <button
                    class="btn btn-sm btn-block btn-success place_button"
                    @click="createWorkoutUser()"
                    @touch="createWorkoutUser()"
                    >
                    <vue-feather size="15" type="check" strokeWidth="2" stroke="white" fill="none"></vue-feather>
                    </button>
                  </div>

                  <!-- место свободно и не выбрано -->
                  <div v-else>
                    <img src="/free.png" class="icon">
                    <div class="place_label">
                      №: {{col.bikeNumber}}
                    </div>
                    <div class="text-success font-size-12px">
                      пусто
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      
      </div>

      <div v-if="isAdmin()" class="mt-3 mb-5 pt-1 my_border_top">
        <div class="ms-3">
          Управление тренировкой:
        </div>

        <div class="row m-0">
          <div class="col-6">
            <button class="btn btn-outline-primary btn-block"
            @click = "editRedirect"
            @touch = "editRedirect"
            >
              <vue-feather size="15" type="tool" stroke="white" strokeWidth="2" fill="none"></vue-feather>
              <div class="d-inline ms-2">Изменить</div>
            </button>
          </div>
          <div class="col-6">
            <div class="btn btn-outline-danger btn-block"
            v-if="!selectedWorkout.isCanceled"
            @click = "workoutCancel"
            @touch = "workoutCancel"
            >
              <vue-feather size="15" type="trash-2" stroke="white" strokeWidth="2" fill="none"></vue-feather>
              <div class="d-inline ms-2">Отменить</div>
            </div>

            <div class="btn btn-outline-success btn-block"
            v-if="selectedWorkout.isCanceled"
            @click = "workoutReturn"
            @touch = "workoutReturn"
            >
              <vue-feather size="15" type="corner-up-left" stroke="white" strokeWidth="2" fill="none"></vue-feather>
              <div class="d-inline ms-2">Возобновить</div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div v-else class="full_screen_loading text-center">
      <vue-feather size="200px" type="loader" animation="spin" animation-speed="slow"></vue-feather>
    </div>
</template>

<script>

export default {
  name: 'WorkoutPage',
  data () {
     return {
       user: {},
       isVisibleUserInfo: false,

       intervalID: 0,
       isRefreshing: true,
       dateUri: '',
       dateId: null,
       selectedWorkout: undefined, // {}
       places: [], // []
       placesOstatok: [], // []
       selectedPlaceNumber: undefined, // integer
       workoutUsers: undefined, // []
       thisUserReservedPlaceNumber: undefined,
    }
  },
  // watch: {
  //   workoutUsers: function () {
  //
  //   },
  // },
  mounted () {
    console.log('mounted');
    // преобразуем dateId из url
    this.dateUri = this.$route.params.datetime;
    let date = this.dateUri.split('_')[1];
    let time = this.dateUri.split('_')[0];
    date = date.split('-').reverse().join('-');
    time = time.replaceAll('-', ':') + ':00';
    this.dateId = date + ' ' + time;

    // автологин по хешу и в случае успеха подтягивание данных с сервера
    this.preLoginByHash();


    // // но если мест всё ещё < 12, остаток обнуляем и садим дальше
    // if (rowsCount < 4) {
    //   for (let j = 0; j < ostatokCount;j ++) {
    //     if (this.places[i] == undefined) {
    //       this.places[i] = [];
    //     }
    //     this.places[i][j] = {
    //       number: bikeNumber,
    //       userName: ''
    //     }
    //   }
    //   this.placesOstatok = [];
    // }
  },
  methods: {
    isAdmin () {
      return (this.user.role=='admin');
    },
    isRaider () {
      return (this.user.role=='raider');
    },
    async workoutReturn () {
      let response = await this.$root.axiosInstance.post('/workoutreturn', {id: this.selectedWorkout.id, hash: this.user.password});
      if (response.data.error) {
        this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
        return;
      }
      this.actualizeAll();
    },
    async workoutCancel () {
      // question - текст сообщения (вопроса)
      const confirmExit = confirm(`Точно отменить тренировку ?`);
      if (confirmExit == false) return;

      let response = await this.$root.axiosInstance.post('/workoutcancel', {id: this.selectedWorkout.id, hash: this.user.password});
      if (response.data.error) {
        this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
        return;
      }
      this.actualizeAll();
    },
    page404Redirect () {
      clearInterval(this.intervalID);
      this.$router.push({ path: '/404'});
    },
    listRedirect () {
      clearInterval(this.intervalID);
      this.$router.push({ path: '/'});
    },
    editRedirect () {
      clearInterval(this.intervalID);
      this.$router.push({ path: '/we/' + this.dateUri });
    },
    bestTrainer () {
      this.$swal({
        position: 'top',
        icon: 'info',
        title: 'Лучший тренер по сайклу',
        showConfirmButton: false,
        timer: 1500
      });
    },
    dynamic () {
      this.$swal({
        position: 'top',
        icon: 'info',
        title: 'Это динамик, висящий на стене',
        showConfirmButton: false,
        timer: 1500
      });
    },
    ekran () {
      this.$swal({
        position: 'top',
        icon: 'info',
        title: 'Это экран для проектора',
        text: 'Первая строка: набрана ли группа. Вторая строка: выбранное место.',
        showConfirmButton: false,
        timer: 2500
      });
    },
    getPlaceContainerClass(col) {
      // место занято нами
      let isOurPlace = (col.user && col.user.phoneId == this.user.phoneId);
      // место выбрано
      let isSelectedPlace = (this.selectedPlaceNumber == col.bikeNumber);
      // место занято кем-то другим
      let isNotOurPlace = (col.user && col.user.phoneId != this.user.phoneId);

      if (isOurPlace) return 'place_container our_place';
      if (isNotOurPlace) return 'place_container not_our_place';
      if (isSelectedPlace) return 'place_container selected';
      return 'place_container';
    },

    async deleteWorkoutUser () {
      if (this.selectedWorkout.isTrainingOver) {
        this.$swal({
          position: 'top',
          icon: 'warning',
          title: 'Запись уже закрыта',
          text: `Запись на тренировку ${this.dateId} полностью закрыта.`,
          showConfirmButton: true
        });
        return;
      }
      if (this.selectedWorkout.isLessThanOneHour) {
        this.$swal({
          position: 'top',
          icon: 'warning',
          title: 'Запрет на снятие брони',
          text: `Осталось менее 4 часов до начала тренировки ${this.dateId}. Снятие брони отключено!`,
          showConfirmButton: true
        });
        return;
      }
      let params = {
        userPhoneId: this.user.phoneId,
        workoutDateId: this.dateId,
        // for user update
        phoneId: this.user.phoneId,
        name: this.user.name,
        role: this.user.role,
        passwordHash: this.user.password,
        isDeleted: 0,
        chatId: this.user.chatId,
        birthDay: this.user.birthDay,
        hash: this.user.password,
        countPro: this.user.countPro,
        countStart: this.user.countStart,
        countLite: this.user.countLite,
        countJunior: this.user.countJunior,
        countMarafon: this.user.countMarafon,
        plusPro: this.selectedWorkout.name == 'Сайкл PRO' ? 1 : 0,
        plusStart: this.selectedWorkout.name == 'Сайкл START' ? 1 : 0,
        plusLite: this.selectedWorkout.name == 'Сайкл LITE' ? 1 : 0,
        plusJunior: this.selectedWorkout.name == 'Сайкл JUNIOR' ? 1 : 0,
        plusMarafon: this.selectedWorkout.name == 'Сайкл МАРАФОН' ? 1 : 0,
      };
      let response = await this.$root.axiosInstance.post('/deleteworkoutuser', params);
      if (response.data.error) {
        this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
        return;
      }
      if (response.data.message) this.$swal({ icon: 'warning', title: 'Очень жаль', text: response.data.message, showConfirmButton: false, timer: 1500});
      // console.log(response);
      this.actualizeAll();
      this.selectedPlaceNumber = undefined;
    },
    async createWorkoutUser () {
      if (this.selectedWorkout.isCanceled) {
        this.$swal({
          position: 'top',
          icon: 'error',
          title: 'Тренировка отменена',
          text: `Тренировка ${this.dateId} отменена. Запись недоступна.`,
          showConfirmButton: true
        });
        return;
      }
      if (this.selectedWorkout.isTrainingOver) {
        this.$swal({
          position: 'top',
          icon: 'warning',
          title: 'Запись уже закрыта',
          text: `Запись на тренировку ${this.dateId} полностью закрыта.`,
          showConfirmButton: true
        });
        return;
      }
      if (!this.isAdmin() && !this.isRaider())  {
        if (this.selectedWorkout.isRecordNotOpenYet) {
          this.$router.push({ path: '/'});
          this.$swal({
            position: 'top',
            icon: 'warning',
            title: 'Запись ещё не открыта',
            text: `Запись на тренировку ${this.dateId} недоступна. Запись открывается за 24 часа до начала тренировки.`,
            showConfirmButton: true
          });
          return;
        }
      }

      let params = {
        userPhoneId: this.user.phoneId,
        workoutDateId: this.dateId,
        place: this.selectedPlaceNumber,
        // for user update
        phoneId: this.user.phoneId,
        name: this.user.name,
        role: this.user.role,
        passwordHash: this.user.password,
        isDeleted: 0,
        chatId: this.user.chatId,
        birthDay: this.user.birthDay,
        hash: this.user.password,
        countPro: this.user.countPro,
        countStart: this.user.countStart,
        countLite: this.user.countLite,
        countJunior: this.user.countJunior,
        countMarafon: this.user.countMarafon,
        plusPro: this.selectedWorkout.name == 'Сайкл PRO' ? -1 : 0,
        plusStart: this.selectedWorkout.name == 'Сайкл START' ? -1 : 0,
        plusLite: this.selectedWorkout.name == 'Сайкл LITE' ? -1 : 0,
        plusJunior: this.selectedWorkout.name == 'Сайкл JUNIOR' ? -1 : 0,
        plusMarafon: this.selectedWorkout.name == 'Сайкл МАРАФОН' ? -1 : 0,
      };
      // console.log(params);
      let response = await this.$root.axiosInstance.post('/createworkoutuser', params);
      if (response.data.error) {
        this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
        return;
      }
      if (response.data.message) this.$swal({ icon: 'success', title: 'Ура!', text: response.data.message, showConfirmButton: false, timer: 1500});

      // console.log(response);
      this.actualizeAll();
      this.selectedPlaceNumber = undefined;
    },

    async actualizeAll (launchedFromInterval) {
      this.isRefreshing = true;
      // this.selectedWorkout = undefined;
      let response =  await this.$root.axiosInstance.get('/workout', {params: {dateId: this.dateId, hash: this.user.password}});
      if (response.data.error) {
        this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
        return;
      }
      if (response.data == '' || response.data == null || response.data == undefined) {
        // Прекращаем автообновление
        clearInterval(this.intervalID);
        // если запущено из интервала каждые 5 секунд, то ничего не делаем
        if (!launchedFromInterval) {
          this.$swal({ icon: 'error', title: '404', text: `Тренировка ${this.dateId} не найдена. Возможно её перенесли на другое время. Выберите другую из списка`});
          this.page404Redirect();
        }
      }
      this.selectedWorkout = response.data;

      // this.workoutUsers = undefined;
      response =  await this.$root.axiosInstance.get('/workoutusers', {params: {dateId: this.dateId, hash: this.user.password}});
      if (response.data.error) {
        this.$swal({ icon: 'error', title: 'Упс...', text: response.data.error});
        return;
      }
      // console.log(response);
      this.workoutUsers = response.data;

      // не записан ли вошедший пользователь. Определение его места
      let ourUser = this.workoutUsers.find((u) => u.phoneId == this.user.phoneId);
      this.thisUserReservedPlaceNumber = ourUser == undefined ? undefined : ourUser.place;

      this.calcPlaces();
      // this.selectedPlaceNumber = this.thisUserReservedPlaceNumber;
      this.isRefreshing = false;
    },
    enoughWorkoutUsers () {
      return (this.workoutUsers.length >= this.selectedWorkout.minPeoples);
    },
    // находит пользователя, который занял Место
    whoReservedPlace (place) {
      // среди занятых мест находим то, которое нас интересует или undefined
      return this.workoutUsers.find((u) => u.place == place);
    },
    async selectPlace(bikeNumber) {
      // сперва актуализируем информацию
      await this.actualizeAll();
      // если текущий пользователь уже записан на занятие -- он не может выбрать другое место
      if (this.thisUserReservedPlaceNumber && this.thisUserReservedPlaceNumber != bikeNumber) {
        this.$swal({ icon: 'warning', title: 'Вы уже записаны на тренировку!', text: `Снимите бронь с места ${this.thisUserReservedPlaceNumber}, а затем выбирайте новое место.`, showConfirmButton: false, timer: 2000});
        return;
      }

      // // если место уже занято нами -- его нельзя выбрать
      // let userWhoReservedPlace = this.whoReservedPlace(bikeNumber);
      // if (userWhoReservedPlace.phoneId == user.phoneId) {
      //   this.$swal({ icon: 'warning', title: 'Нельзя выбрать это место', text: `Так как оно уже ваше!`});
      //   return;
      // };

      // если место занято и занято кем-то другим -- его нельзя выбрать
      let userWhoReservedPlace = this.whoReservedPlace(bikeNumber);
      if (userWhoReservedPlace && userWhoReservedPlace.phoneId != this.user.phoneId) {
        this.$swal({ icon: 'warning', title: 'Нельзя выбрать это место', text: `Это место уже занято участником ${userWhoReservedPlace.name}`});
        return;
      }
      this.selectedPlaceNumber = bikeNumber;
    },
    calcPlaces () {
      let columnsCount = 6;

      let bikeNumber = 1;
      let i = 0;
      // первые 5 ячеек
      for (let j = 0; j < columnsCount-1; j++) {
        if (this.places[i] == undefined) {
          this.places[i] = [];
        }
        if (bikeNumber <= this.selectedWorkout.maxPlaces) {
          this.places[i][j] = {
            bikeNumber: bikeNumber,
            user: this.whoReservedPlace(bikeNumber),
            marginTop: 10*Math.abs(columnsCount/2-j-1),
          }
          bikeNumber++;
        }
      }


      i = 1;
      // вторые 6 ячеек
      for (let j = 0; j < columnsCount; j++) {
        if (this.places[i] == undefined) {
          this.places[i] = [];
        }
        if (bikeNumber <= this.selectedWorkout.maxPlaces) {
          this.places[i][j] = {
            bikeNumber: bikeNumber,
            user: this.whoReservedPlace(bikeNumber),
            marginTop: 10*Math.abs(columnsCount/2-j-0.5),
          }
          bikeNumber++;
        }
      }

 
    },

    // --- FOR ANY PAGE METHODS
    async preLoginByHash() {
      // Получим хеш пароля пользователя из localStorage
      let passwordHash = localStorage.getItem('hash');
      console.log(passwordHash);
      if (passwordHash == null) {
        this.$router.push({ name: 'LoginPage' });
        return;
      }
      try {
        let response = await this.$root.axiosInstance.post('/loginbyhash', {hash: passwordHash});
        if (response.data.loginOk == true) {
          this.user = response.data.user;
          console.log(`Вошли по хешу, как ${this.user.name}`);

          // mounted обновление списка тренировок!
          await this.actualizeAll();
          if (this.selectedWorkout.isCanceled) {
            this.$swal({ icon: 'error', title: 'Тренировка отменена', text: `Тренировка ${this.dateId} отменена. Запись недоступна.`});
          }
          // для админа или рейдера закрываем на это глаза
          if (!this.isAdmin() && !this.isRaider())  {
            if (this.selectedWorkout.isRecordNotOpenYet) {
              this.$router.push({ path: '/'});
              this.$swal({
                position: 'top',
                icon: 'warning',
                title: 'Запись ещё не открыта',
                text: `Запись на тренировку ${this.dateId} недоступна. Запись открывается за 24 часа до начала тренировки.`,
                showConfirmButton: true
              });
              return;
            }
          }

          this.intervalID = setInterval(this.actualizeAll, 5000, true);
        }
        else {
          // если логин по хешу неуспешный -- значит он кривой
          console.log('Ошибка во время авто-логина. Хеш будет удалён.');
          localStorage.removeItem('hash');
          this.$router.push({ name: 'LoginPage' });
        }
      } catch (err) {
        alert('Сервер не отвечает \n\n' + err);
        localStorage.removeItem('hash');
      }
    },
    logout () {
      // question - текст сообщения (вопроса)
      const confirmExit = confirm('Точно выйти?');
      if (confirmExit == false) return;
      localStorage.removeItem('hash');
      location.reload();
    },
    // показать, скрыть
    showHideUserInfo (e) {
      e.stopPropagation();
      this.isVisibleUserInfo = !this.isVisibleUserInfo; // if (e.target.closest('#userInfoButton') != undefined)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cursor-pointer-wrapper {
  cursor: pointer;
}

.align-middle {
  vertical-align: middle;
}
.trainer {
  right:20px;
  position:absolute;
  width:100px;
  height:100px;
  padding-top:5px;
  border: 0px;
  background-color: #22282e;
  cursor: pointer;
  border-radius:30px;
}
.full_screen_loading {
  background-color: #32383e;
  height: calc(100vh - 50px);
}
/* .doska {
  height:60px;
  width:90%;
  margin-left:5%;
  background-image: url(/doska-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */
.icon {
  margin-top: 20px;
  height: 35px; width: 35px;
}
.free {
  color: lightgreen;
}
.reserv {
  color:red;
}
.font-size-8px {
  font-size: 8px;
}
.font-size-10px {
  font-size: 10px;
}
.font-size-12px {
  font-size: 12px;
}
.font-size-14px {
  font-size: 14px;
}
.font-size-16px {
  font-size: 16px;
}
.font-size-18px {
  font-size: 18px;
}
.font-size-20px {
  font-size: 20px;
}

mb-0 {
  margin-bottom:0;
}
.table_container {
  margin-top:90px;
  padding-left:5px;
  padding-right:5px;            max-width: 600px; /* Установите максимальную ширину для родительского контейнера */
}
table {
  width: 50%;
  table-layout: fixed; /* фиксированная ширина столбцов */
}
tr {
  height: 120px;
}
td {
  height: 120px;
  /* margin-top:5px!important; */
  padding-left:3px!important;
  padding-right:3px!important;
  padding-bottom:0px!important;
  padding-top:20px!important;
  border: 0!important;
  cursor: pointer;
  border-radius:10px;
}
.place_container {
  padding-top:5px;
  border-radius:10px;
  position: relative;
  height:150px;
  border: 1px solid white;
}
.place_label {
  font-size:12px;
}
.place_button {
  width: 100%;
  position: absolute;
  bottom:0px;
  left:0px;
  height: 32px;
  font-size: 13px;
  border-radius: 0px 0px 10px 10px;
  padding-left:4px;
  padding-right:4px;
}

.scalable-text {
  width: 100%; /* Текст займет 100% ширины родительского элемента */
  height: 100%; /* Текст займет 100% высоты родительского элемента */
  font-size: 1.5em; /* Размер шрифта подберите в зависимости от ваших предпочтений */
  position: absolute; /* Абсолютное позиционирование, чтобы занять всю доступную площадь родительского элемента */
  top: 50%; /* Размещаем текст по вертикали по центру */
  left: 50%; /* Размещаем текст по горизонтали по центру */
  transform: translate(-50%, -50%); /* Центрируем текст относительно центра родительского элемента */
}

.place_container:hover {
  /* background-color:black; */
  box-shadow: 0 0 5px 2px;
}
.selected {
  box-shadow: 0 0 5px 2px;
  /* background-color:green; */
}
.our_place {
  border: 1px solid white;
}
.not_our_place {
  border: 1px solid white;
  background-color: #22282e;
}

.my_border_bottom {
  border-bottom: 1px solid gray;
}
.my_border_top {
  border-top: 1px solid gray;
}
</style>
